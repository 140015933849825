<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <!--          <b-button-->
          <!--            variant="primary"-->
          <!--            :to="{ name: 'apps-invoice-add'}"-->
          <!--          >-->
          <!--            Add Record-->
          <!--          </b-button>-->
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!--      <template #head(invoiceStatus)>-->
      <!--        <feather-icon-->
      <!--          icon="TrendingUpIcon"-->
      <!--          class="mx-auto"-->
      <!--        />-->
      <!--      </template>-->

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Client -->
      <template #cell(agent)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            <!--            {{ data.item.agent }}@{{ data.item.prefix }}-->
            {{ data.item.agent }}
          </span>

        </b-media>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="resolveInvoiceStatusVariant(data.value)"
        >
          {{ data.value }}
        </b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <template v-if="$can('update', 'invoice')">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              :class="data.item.status ==='paid' ? 'text-muted' : 'cursor-pointer'"
              size="16"
              @click="makePaid(data.item)"
            />
            <b-tooltip
              title="make paid"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />
          </template>

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mx-1"
            @click="$router.push({ name: 'invoice-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>

      <template #custom-foot>
        <tr v-if="sumInvoice">
          <td />
          <td class="text-right">
            รวม
          </td>
          <td>{{ sumInvoice.turnover }}</td>
          <td>{{ sumInvoice.winlose }}</td>
          <td />
          <td>{{ sumInvoice.total }}</td>
          <td />
          <td />
          <td />
        </tr>
        <tr v-else>
          <td colspan="11">
            No data available
          </td>
        </tr>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import reportService from '@/service/reportService'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'prepare',
      'created',
      'paid',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,
      resolveInvoiceStatusVariant,
      sumInvoice,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,
      sumInvoice,
      avatarText,
      resolveInvoiceStatusVariant,
    }
  },
  // mounted() {
  //   if (this.$can('update', 'invoice') === false) {
  //     this.fields.splice(4, 3)
  //     this.small = false
  //   }
  // },
  methods: {
    makePaid(item) {
      if (item.status === 'paid') {
        return
      }
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณต้องการจะบันทึกการชำระเงินใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const res = await reportService.putAuthen(`invoice/${item.id}`)
          if (res.status === 200) {
            this.$refs.refInvoiceListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: res.data,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: res.data,
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
