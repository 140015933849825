/* eslint-disable */
import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import reportService from '@/service/reportService'

export default function useInvoicesList() {
    // Use toast
    const toast = useToast()

    const refInvoiceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'id', label: '#', sortable: true},
        {key: 'agent', sortable: false},
        {key: 'turnover', sortable: false},
        {key: 'winlose', sortable: false},
        {key: 'commission', label: 'PT(%)', sortable: false},
        {key: 'total', sortable: false},
        {key: 'issuedDate', sortable: false , class: 'text-nowrap',},
        {key: 'status', sortable: false},
        {key: 'actions'},
    ]
    const perPage = ref(25)
    const totalInvoices = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5,10, 25, 50, 100 , 500]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const sumInvoice = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalInvoices.value,
        }
    })

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })

    const fetchInvoices = (ctx, callback) => {
        const filter = {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,
        }

        if (filter.status === null){
            filter.status = "";
        }
        const startRow = (filter.page - 1) * filter.perPage

        reportService.getAuthen(`invoice?q=${filter.q}&perPage=${filter.perPage}&page=${startRow}&sortBy=${filter.sortBy}&sortDesc=${filter.sortDesc}&status=${filter.status}`).then(res => {
            if (res.status === 200) {
                const {invoices, total , sum} = res.data

                callback(invoices)
                totalInvoices.value = total
                sumInvoice.value = sum
            } else {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching invoices' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            }
        })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveInvoiceStatusVariant = status => {
        if (status === "prepare") return "light-secondary"
        if (status === "created") return "light-primary"
        if (status === "paid") return "light-success"
    }

    return {
        fetchInvoices,
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,

        statusFilter,
        sumInvoice,
        resolveInvoiceStatusVariant,
        refetchData,
    }
}
